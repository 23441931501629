import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_form_modal = _resolveComponent("form-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_table, {
      ref: "tableRef",
      opts: _ctx.tableOpts
    }, {
      button: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, { onClick: _ctx.showModal }, {
              default: _withCtx(() => [
                _createTextVNode("分润开关")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, { onClick: _ctx.showCloseAuth }, {
              default: _withCtx(() => [
                _createTextVNode("权限开关")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["opts"]),
    _createVNode(_component_form_modal, { opts: _ctx.modalOpts }, null, 8, ["opts"]),
    _createVNode(_component_form_modal, { opts: _ctx.closeAuthOpts }, null, 8, ["opts"])
  ]))
}