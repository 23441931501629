
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import { SearchTableExposeType } from '@/types/types.config';

import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';

/**
 * 分润关闭
 */
export default defineComponent({
  name: 'ProfitClose',
  components: { FormModal, SearchTable },
  setup() {
    const { https, mapper, message, constants } = useInject();
    const { userMeta } = useGetters();
    let agent = {} as any;

    const tableRef = ref<SearchTableExposeType>();
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'targetUid',
              label: '代理',
              type: 'agent',
              required: true,
              evaluate: (data: DataItem) => {
                agent = data;
                return undefined;
              }
            }
          ]
        },
        remote: {
          action: https?.market.pageProfitClose
        }
      },
      cols: [
        {
          field: 'uid',
          label: '代理ID'
        },
        {
          field: 'closeAmt',
          label: '关闭金额'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'startTime',
          label: '开始时间'
        },
        {
          field: 'endTime',
          label: '结束时间'
        },
      ]
    };

    const changeStatus = () => {
      modalOpts.content.entity.status = !modalOpts.content.entity.status;
      https?.market.editProfitClose(modalOpts.content.entity).then(res => {
        modalOpts.visible = false;

        if (res.respType === 'OK') {
          message?.success(`用户分润状态已${modalOpts.content.entity.status ? '开启' : '关闭'}`);
          tableRef.value?.expose.refresh();
        } else {
          message?.warn(res.respMesg);
        }
      });
    };

    const changeCloseAuth = () => {
      closeAuthOpts.content.entity.status = !closeAuthOpts.content.entity.status;
      https?.market.editCloseAuth(closeAuthOpts.content.entity).then(res => {
        closeAuthOpts.visible = false;

        if (res.respType === 'OK') {
          message?.success(`用户分润权限已${closeAuthOpts.content.entity.status ? '开启' : '关闭'}`);
          tableRef.value?.expose.refresh();
        } else {
          message?.warn(res.respMesg);
        }
      });
    };

    const modalOpts = reactive({
      title: '代理分润开关',
      visible: false,
      footer: false,
      content: {
        entity: {} as any,
        cols: [
          {
            field: 'uid',
            label: '代理ID',
            disabled: true,
          },
          {
            field: 'status',
            label: '状态',
            type: 'switch',
            options: mapper?.common.enableBool,
            disabled: () => agent.type !== constants?.enums.userType.agent,
            action: changeStatus
          }
        ]
      }
    });

    const closeAuthOpts = reactive({
      title: '代理分润权限开关',
      visible: false,
      footer: false,
      content: {
        entity: {} as any,
        cols: [
          {
            field: 'targetUid',
            label: '代理ID',
            disabled: true,
          },
          {
            field: 'status',
            label: '状态',
            type: 'switch',
            options: mapper?.common.enableBool,
            disabled: () => agent.type !== constants?.enums.userType.agent,
            action: changeCloseAuth
          }
        ]
      }
    });

    const showModal = () => {
      if (!agent.uid) {
        message?.warn('请输入待查询的代理信息');
        return;
      }

      https?.market.getCurrentTurn({ targetUid: agent.uid }).then(res => {
        if (res.respType === 'OK') {
          modalOpts.content.entity = { uid: agent.uid, status: res.respData, remark: '', operator: userMeta.uid };
          modalOpts.visible = true;
        }
      });
    };

    const showCloseAuth = () => {
      if (!agent.uid) {
        message?.warn('请输入待查询的代理信息');
        return;
      }

      https?.market.getCloseAuth({ targetUid: agent.uid }).then(res => {
        if (res.respType === 'OK') {
          closeAuthOpts.content.entity = { targetUid: agent.uid, status: res.respData };
          closeAuthOpts.visible = true;
        }
      });
    };
    return { tableRef, tableOpts, modalOpts, closeAuthOpts, showModal, showCloseAuth };
  }
});
